
.Snowflake {
	display: inline-block;
	width: 0.1%;
	color: #2d1602;
	opacity: 0;
	font-size: 120px;
	margin: 0;
	padding: 0;
	animation: fall 16s linear infinite;
	@media (max-width: 450px) {
		color: rgb(45, 22, 2, 0.8) !important;
	}
}

@keyframes fall {
	0% {
		opacity: 0;
	}
	3% {
		opacity: 0.9;
	}

	90% {
		opacity: 0.9;
	}
	100% {
		transform: translate(0, 95%);
		opacity: 0;
	}
}

